<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VBtn
              icon
              @click="$router.go(-1)"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>

            <VToolbarTitle>Редактирование контента</VToolbarTitle>

            <VSpacer />

            <VBtn
              depressed
              color="primary"
              @click="archiveContent"
            >
              <VIcon left>
                $archive
              </VIcon>

              Архивировать контент
            </VBtn>
          </VToolbar>

          <VDivider />

          <VCardText>
            <VRow>
              <VCol cols="6">
                <VTextField
                  v-model="title"
                  label="Название"
                />
                <VTextarea
                  v-model="body"
                  label="Содержимое"
                />
                <VTextarea
                  v-model="description"
                  label="Описание"
                />
              </VCol>
            </VRow>
          </VCardText>

          <VCardActions>
            <VBtn
              color="primary"
              @click="saveContent"
            >
              Сохранить
            </VBtn>
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'ContentEdit',
  inject: ['Names'],
  data() {
    return {
      title: '',
      description: '',
      body: '',
    };
  },
  computed: {
    contentId() {
      return this.$route.params.contentId;
    },
  },
  async created() {
    await this.getContent();
  },
  methods: {
    goToContentList() {
      this.$router.push({ name: this.Names.R_CONTENT_STORAGE_CONTENTS });
    },
    async getContent() {
      try {
        const response = await this.$di.api.ContentStorage.contentItemGet({ id: this.contentId });

        this.title = response.item.content.title || '';
        this.body = response.item.content.body || '';
        this.description = response.item.description || '';
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
    async saveContent() {
      try {
        const payload = {
          id: this.contentId,
          content: {
            title: this.title,
            body: this.body,
          },
          description: this.description,
        };

        await this.$di.api.ContentStorage.contentItemUpdate(payload);
        this.$di.notify.snackSuccess('Контент успешно изменён');
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
    async archiveContent() {
      try {
        await this.$di.api.ContentStorage.contentItemArchive({ id: this.contentId });
        this.goToContentList();
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
  },
};
</script>
